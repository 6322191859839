<template>
  <div class="customerCases" :style="backgroundStyle">
    <div class="sidebar">
      <div v-for="(item, index) in sidebarTitle" :key="index">
        <div class="title">{{ getTitle(item) }}</div>
        <ul>
          <li
            :class="item"
            @mouseenter="mouseenterFn(item, indexs)"
            v-for="(items, indexs) in sidebarData[item]"
            :key="indexs"
          >
            {{ items.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import one from '../../../assets/案例配图/phone/RafflesCityShoppingCentre.png'
import two from '../../../assets/案例配图/phone/LANDMARKRIVERSIDEPARK.png'
import three from '../../../assets/案例配图/phone/WaterfrontMall.png'
import four from '../../../assets/案例配图/phone/AnDaShengShoppingCenter.png'
import five from '../../../assets/案例配图/phone/GelatoParadisoShanghai.png'
import six from '../../../assets/案例配图/phone/T3Tea&More.png'
import seven from '../../../assets/案例配图/phone/BIOWOOSOO.png'
import eight from '../../../assets/案例配图/phone/LaoFengXiang.png'
import nine from '../../../assets/案例配图/phone/Baleno.png'
import ten from '../../../assets/案例配图/phone/Tinghouse.png'
import eleven from '../../../assets/案例配图/phone/CentroProliton.png'
// 接收方页面的组件中
import { EventBus } from '@/utils/eventBus.js'
export default {
  data() {
    return {
      path: '',
      sidebarData: {
        ShoppingCenter: [
          {
            title: 'Raffles City Shopping Centre',
            backgroundImage: one
          },
          {
            title: 'Landmark Riverside Park',
            backgroundImage: two
          },
          { title: 'Waterfront Mall', backgroundImage: three },
          { title: 'An Da Sheng Shopping Center', backgroundImage: four }
        ],
        FB: [
          {
            title: 'Gelato Paradiso Shanghai',
            backgroundImage: five
          },
          {
            title: 'T3 Tea & More',
            backgroundImage: six
          }
        ],
        Retails: [
          {
            title: 'BIOWOOSOO',
            backgroundImage: seven
          },
          {
            title: 'Lao Feng Xiang',
            backgroundImage: eight
          },
          {
            title: 'Baleno',
            backgroundImage: nine
          },
          {
            title: 'Ting house',
            backgroundImage: ten
          }
        ],
        Supermarket: [
          {
            title: 'Centro Proliton',
            backgroundImage: eleven
          }
        ]
      },
      sidebarTitle: [],
      backgroundImage: one
    }
  },
  mounted() {
    this.path = this.$route.path
    EventBus.$on(this.path, data => {
      // 处理事件
      this.$nextTick(() => {
        this.toLocal(data)
      })
    })
  },
  created() {
    this.getSidebarTitle()
  },
  methods: {
    getTitle(val) {
      if (val == 'ShoppingCenter') {
        return 'Shopping Center'
      } else if (val == 'FB') {
        return 'F&B'
      } else {
        return val
      }
    },
    toLocal(Id) {
      //锚点存在跳转
      if (Id) {
        this.mouseenterFn(Id, 0)
      }
    },
    getSidebarTitle() {
      for (let item in this.sidebarData) {
        this.sidebarTitle.push(item)
      }
    },
    mouseenterFn(item, index) {
      this.$nextTick(() => {
        let els = document.querySelectorAll(`.${item}`)
        let lis = document.querySelectorAll(`ul li`)
        lis.forEach(item => {
          item.style.borderBottom = '2px solid transparent'
        })
        els[index].style.borderBottom = '2px solid rgba(0, 92, 255, 1)'
        this.backgroundImage = this.sidebarData[item][index].backgroundImage
      })
    }
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
        backgroundSize: 'cover', // 根据需要调整
        backgroundRepeat: 'no-repeat' // 根据需要调整
        // 其他样式
      }
    }
  },
  beforeDestroy() {
    EventBus.$off(this.path)
  }
}
</script>

<style lang="less" scoped>
.customerCases {
  position: relative;
  height: 685px;
  .sidebar {
    padding: 42px 0px 0px 31px;
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    top: 0px;
    left: 0px;
    width: 233px;
    height: 100%;
    .title {
      font-size: 12px;
      color: rgba(0, 92, 255, 1);
      font-weight: 900;
      margin-bottom: 15px;
    }
    ul {
      li {
        // height: 34.21px;
        position: relative;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        line-height: 25px;
        color: rgba(56, 56, 56, 1);
        margin-bottom: 10px;
        border-bottom: 2px solid transparent;
      }
    }
  }
}
</style>
